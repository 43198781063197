
  /* src/components/Contact.css */
.contact {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  color: #fff;
}

.contact h2 {
  font-weight: 200;
  font-size: 2em;
}

.contact p{
  padding: 20px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .contact h2 {
    font-size: 1.5rem;
  }

  .contact p{
    font-size: 10px;
    padding: 5px;
  }
}
