/*body {
  margin: 0;
  font-family: 'Arial, sans-serif';
  background-color: #121212;
  color: white;
}

.container {
  padding: 20px;
}*/

/* src/App.css */
.App {
  background-image: url('C:\app\programs\SC\landing-page\src\assets\Doodle\doodle3.svg');
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

