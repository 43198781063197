

  /* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet');

.landing-page {
    text-align: center;
    color: #fff;
  }
  
  .header {
    padding: 20px;
    text-align: center;
  }

  .heading1{
    color:#FF4D4D;
    font-family: "Gochi Hand", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 6.5rem;
    margin-top: 10rem;
  }

  .heading2{
    color:#ffffff;
    font-family: "Gochi Hand";
    font-size: 6.5rem;
  }

  .heading3{
    color: #fff;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: 40px;
    padding: 0;
  }
  
  .storeButtons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 10vh;
  }

  .playstore-button{
    width: 10rem;
    height: 5rem;
    cursor: pointer;
    border: none;
    padding: 10px;
  }

  .appstore-button{
    width: 11rem;
    height: 4.3rem;
    cursor: pointer;
    border: none;
    padding: 10px;
  }

  .header2 {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
  
  .card {
    border-radius: 8px;
    padding: 40px;
    width: 400px;
    text-align: left;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0);
  }

  .title {
    color: #FF4D4D;
    margin: 0 0 10px 0;
    font-size: 24px;
  }

  .description {
    color: #fff;
    margin: 0;
    font-size: 16px;
  }
  

  .social-icons button {
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    margin: 0 10px;
    font-size: 20px;
  }
  

  .screens-section {
    padding: 20px 0;
    margin-bottom: 5rem;
  }
  
  .its-all-about{
    color:#FF4D4D;
    font-family: "Gochi Hand", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 5rem;
  }

  .carousel-section {
    background-color: black;
    padding-bottom: 20px;
  }

  .footer-section {
    background-color: black;
    padding: 20px 0;
  }


  /* Animation Classes */
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

/* Ensure the static state has no animations */
.static {
  transform: translateY(0);
  opacity: 1;
}

/* Add animations for scroll-based classes */
.slideUp {
  animation: slideUp 1s ease-out;
}

.slideDown {
  animation: slideDown 1s ease-out;
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.delay-1s {
  animation-delay: 1s;
}

.delay-2s {
  animation-delay: 2s;
}

  @media (max-width: 768px) {

    .heading1{
      font-size: 2rem;
      margin-top: 3rem;
    }

    .heading2{
      font-size: 2rem;

    }

    .heading3{
      font-size: 20px;
      margin-top: 4rem;
      padding: 0;
    }

    .header2{
      height: 30vh;
    }

    .playstore-button{
      width: 5rem;
      height: 3rem;
      padding: 0;
    }

    .appstore-button{
      width: 6rem;
      height: 1.5rem; 
      padding: 0;
    }

    .card {
        width: 80%;
        height: 10%;
    }

    .title {
      color: #FF4D4D;
      margin: 0 0 10px 0;
      font-size:  18px;
    }
  
    .description {
      color: #ddd;
      margin: 0;
      font-size: 14px;
    }
    
    .screens-section {
      padding: 20px 0;
      margin-bottom: 2rem;
    }

    .its-all-about{
      font-size: 2rem;
    }

    .carousel-section {
      padding-bottom: 5px;
    }
    .footer-section {
      padding: 0 0;
    }
}