.products{
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  color: #fff;
}
  
  .products h2 {
    font-weight: 200;
    font-size: 2em
  }
  
  .products ul {
    list-style: disc;
    padding: 20px;
  }
  
  .products li {
    margin: 5px 0;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .products h2 {
      font-size: 1.5rem;
    }

    .products ul {
      font-size: 10px;
      padding: 5px;
      padding-left: 20px;
    }
    
    .products li {
      margin: 5px 10px;
    }
    
  }
  