
/* src/components/Carousel.css */
.carousel-container {
  width: 100%;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10rem;
}

.carousel-image {
  width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .carousel-container {
    margin-bottom: 3rem;
  }
}
