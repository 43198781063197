/* src/components/Navbar.css */

html {
  scroll-behavior: smooth;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.navbar-logo{
  display: flex;
}

.logoN{
  width: 10rem;
  height: 40px;
  margin-top: 15px;
  margin-left: 0px;
}


.nav-links {
  display: flex;
  gap: 20px;
  align-items: center;
}

.nav-links a {
  justify-content: space-around;
  text-decoration: none;
  color:#ffffff;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
}

.hamburger-menu {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 4px;
  z-index: 1000; /* Ensure it stays above other content */
}

.hamburger-menu .line {
  width: 25px;
  height: 3px;
  background-color: white;
}

.dropdown-container {
  position: relative;
}

.download-button {
  background-color: #FF4D4D;
  font-size: larger;
  font-weight: bold;
  color: #fff;
  padding: 10px 25px;
  cursor: pointer;
  border-radius: 30px;
}

.download-button:hover {
  background-color: #e14b4e;
}

/* Dropdown menu */
.dropdown-menu {
  position: absolute;
  top: 100%; /* Position it below the button */
  left: 0;
  background-color: black;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  padding: 10px 0;
  width: 150px;
  display: flex;
  flex-direction: column;
}

.dropdown-menu button {
  background: none;
  border: none;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  padding: 8px 15px;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
}

.dropdown-menu button:hover {
  background-color: #e14b4e;
  color: black;
}


/* Media query for mobile view */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    background-color: #101010;
    flex-direction: column;
    padding: 10px 0;
    z-index: 999; /* Ensure it stays above other content */
  }

  .nav-links.mobile {
    display: flex;
  }

  .logoN{
    width: 6rem;
    height: 25px;
    margin-top: 10px;
    margin-left: 0px;
  }


  .hamburger-menu {
    display: flex;
  }
}