
  /* src/components/Screens.css */
.screens-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px 0;
  }
  
  .screen-image {
    width: 80%;
    height: 100%;
    object-fit: contain;
    transition: transform 0.3s ease;
  }

  @keyframes zoomIn {
    from {
      transform: scale(0.8);
      opacity: 0.5;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }

  /* Larger screens (desktops) */
@media (min-width: 1200px) {
  .screen-image {
    width: 70%;
    height: auto;
  }
}

/* Medium screens (tablets in landscape or smaller laptops) */
@media (max-width: 1199px) and (min-width: 768px) {
  .screen-image {
    width: 90%;
    height: auto;
  }
}

/* Small screens (tablets in portrait or large phones) */
@media (max-width: 767px) and (min-width: 480px) {
  .screen-image {
    width: 95%;
    height: auto;
  }
}

/* Extra small screens (phones) */
@media (max-width: 479px) {
  .screen-image {
    width: 100%;
    height: auto;

  }
}
 

  /*.slick-center {
    animation: zoomIn 1s cubic-bezier(0.25, 0.1, 0.25, 1);
  }*/
  
