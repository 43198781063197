
  /* src/components/About.css */
.about {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
  color: #fff;
  border-bottom: #ffffff83 solid; ;
}

.about h1 {
  font-weight: 200;
  font-size: 2em;
  color: #fff;
}

.about p{
  margin-bottom: 5rem;
}

@media (max-width: 768px) {
  .about h1 {
    font-size: 1.5rem;
  }

  .about p{
    font-size: 10px;
    margin-bottom: 2rem;
  }
}