/* src/components/Footer.css */
.footer {
  background-color: black;
  color: #fff;
  padding: 20px 80px;
  text-align: left;
  }

  .Logo-content{
    text-align: left;
    padding-bottom: 30px ;
    border-bottom: #ffffff83 solid;
  }

  .logoN1{
    width: 10rem;
    height: 30px;
    margin-top: 10px;
    margin-left: 0px;
  }
  
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: #ffffff83 solid;
  }

  
  .socials-content{
    width: 100%;
    border-bottom: #ffffff83 solid;
  }

  .socials {
    display: flex;
    align-items: center;
  }

  div.socials p{
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
    font-size: 2em;
  }
  
  .socials .icons i {
    margin: 0 5px;
    font-size: 2em;
  }
  
  /* Footer bottom */
  .footer-bottom {
    text-align: right;
    margin-top: 20px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {

    .footer {
      padding: 10px 40px;
    }

    .Logo-content{
      padding-bottom: 10px ;
    }


    div.socials p{
      font-size: 1.5rem;
    }
    .socials .icons i {
      font-size: 20px;
      cursor: pointer;
    }

    .footer-content {
      flex-direction: column;
      align-items: center;
    }

    .footer-bottom {
      font-size: 10px;
    }
  }
  